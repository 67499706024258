.user-text-fields {
    width: 80%;
}

.text-field-container {
    margin-bottom: 20px;
}

.user-text-fields label {
    display: block;
    margin-bottom: 5px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
}

.text-field {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Inter';
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 0px;
    background-color: #EDF2F7;
    box-sizing: border-box; 
}

.error-text {
    color: #718096;
    font-size: 11px;
    margin-bottom: 10px;
    display: block;
    font-family: 'Inter';
    font-weight: 400;
    font-family: 'Inter';
    font-weight: 500;
}