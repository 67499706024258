.listDesign {
    background-color: rgb(237, 237, 237);
    padding-top: 15px;
    padding-bottom: 5px;
    border-radius: 10px;
}

.listDesign li {
    margin-bottom: 10px;
}

.listflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    /* Adjust spacing between columns as needed */
    padding: 10px;
}

.listflex > * {
    flex: 1; /* Makes each column take up equal space */
    text-align: center; /* Centers the text in each column */
}

.payment-methods-card {
    width: 100%;
    max-width: 740px;
}

.payment-methods-card-add-button {
    margin-top: 10px;
    width: 250px
}

.payment-methods-card-button {
    text-align: right; /* Aligns buttons to the right */
}