#card-element {
    min-width: 300px;
  }
  
  #card-element .StripeElement {
    height: 100%;
    padding: 10px 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #fff;
  }

  .add-card {
    margin-top: 10px;
  }