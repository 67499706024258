.form {
  display: flex;
  flex-direction: column;
  align-items: fill;
  padding-bottom: 20px;
  padding-right: 20px;
}

.form-group-company {
  display: flex;
  justify-content: space-between;
  gap: 35px;
}

.form-group {
  width: 100%;
}

.form-group-card {
  margin-bottom: 20px;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-weight: 400;
  color: #494949;
  font-family: 'Inter';
}

.form-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.form-input:disabled {
  background-color: #e9ecef; /* Light gray background */
  color: #6c757d; /* Dimmed text color */
  border-color: #ced4da; /* Lighter border */
  cursor: not-allowed; /* Shows a 'not-allowed' cursor on hover */
}

.form-input:focus {
  outline: none;
  border-color: #3F6EE8;
}

.has-error {
  border-color: red;
}

.form-error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.form-error-red {
  color: red;
}

.form-btn {
  width: 40%;
}


@media only screen and (max-width: 600px) {
  .form-btn {
    width: 100%;
  }
}