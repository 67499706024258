.popup-form-input{
    border: solid 1px #e2e2e2;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
    border-radius: 6px;
    height: 25px;
    width: 100%;
}

.popup-form-delete-button {
    margin-top: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #d53b3b;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    font-family: 'Inter';
    }

    .popup-form-ldsc{
        font-family: 'Inter';
        font-size: 12px; 
        color: #5c5c5c;
    }