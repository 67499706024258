.admin-content {
    display: flex;
    position: relative;
    justify-content: space-between;
    background-color: #F8FAFC;
    min-height: 100vh;

}

.side-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    background-color: #ECECEC;
    position: fixed;
    height: 100%;
    transition: all 0.3s ease;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.seperatorMenu {
    margin-top: 20px;
    height: 20px;
    width: 90%;
    border-top: 1px #dadada solid;

}

.LD-container {
    margin-bottom: 30px;
}

.side-menu.hidden {
    transform: translateX(-100%);
}

.logoContainer {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
}

.menuItemContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.logoHolder {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.content {
    flex-grow: 1;
    padding: 16px;
    margin-left: 200px;
    transition: margin-left 0.3s ease;
    background-color: #F8FAFC;
    min-width: 768;
}



.menuIcon {
    margin-right: 10px;
}

.menuItemContainer button {
    display: flex;
    margin-bottom: 8px;
    margin-left: 20px;
    margin-right: 10px;
    padding: 10px;
    color: #494949;
    background-color: transparent;
    border: 0px solid #ccc;
    cursor: pointer;
    font-size: 14px;
    border-radius: 10px;
    font-weight: 400;
    font-family: 'Inter';
    padding-left: 20px;
}

.menuItemContainer button:hover {
    font-size: 14.5px;
}

.menuItemContainer button.selected {
    color: #272727;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid #ccc;
}

.menu-header {
    font-weight: bold;
    padding-left: 20px;
    padding-bottom: 5px;
}

.menu-toggle-btn {
    display: none;
    position: fixed;
    left: 16px;
    top: 16px;
    z-index: 1;
    background-color: #f0f0f0;
    border: none;
    font-size: 24px;
    padding: 8px;
}

.menu-toggle-btn.collapsed {
    background-color: white;
}

.back-btn {
    display: none;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    z-index: 2;
  }
  
  .header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0px;
    height: 44px;
  }
  
  .backBtn {
    display: none;
    align-items: center;
    justify-content: center;
  }
  

@media screen and (max-width: 768px) {
    .menu-toggle-btn {
        display: block;
    }

    .side-menu {
        width: 200;
    }

    .content {
        padding-top: 24px;
        margin-left: 0;
    }

    .backBtn {
        display: flex;
      }
}